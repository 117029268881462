import './src/App.css';
import './src/index.css';

export const onClientEntry = () => {
  // Function to load scripts with cache busting
  const loadScript = (src, attributes = {}, async = true, defer = true) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src + `?v=${new Date().getTime()}`; // Cache busting
      script.async = async;
      script.defer = defer;

      Object.keys(attributes).forEach(key => {
        script.setAttribute(key, attributes[key]);
      });

      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const addPreconnectLink = (href) => {
    const link = document.createElement("link");
    link.rel = "preconnect";
    link.href = href;
    link.crossOrigin = "anonymous";
    document.head.appendChild(link);
  };

  // Add preconnect links
  addPreconnectLink("https://cdnjs.cloudflare.com");
  addPreconnectLink("https://kit.fontawesome.com");
  addPreconnectLink("https://chatbot.srivengroups.com");
  addPreconnectLink("https://cdn.jsdelivr.net");
  addPreconnectLink("https://fonts.googleapis.com");

  const jsonLDScript = document.createElement("script");
  jsonLDScript.type = "application/ld+json";
  jsonLDScript.innerHTML = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "name": "SRIVEN GROUPS",
    "url": "https://srivengroups.com/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://srivengroups.com/properties/{search_term_string}",
      "query-input": "required name=search_term_string"
    }
  });
  document.head.appendChild(jsonLDScript);

  const jsonLDScript2 = document.createElement("script");
  jsonLDScript2.type = "application/ld+json";
  jsonLDScript2.innerHTML = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Corporation",
    "name": "SRIVEN GROUPS",
    "alternateName": "srivengroups",
    "url": "https://srivengroups.com/",
    "logo": "https://srivengroups.com/static/web%20logo%20(1)-6234c33b97c6f3659b72d8d9b46426b1.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "9036053618",
      "contactType": "customer service",
      "areaServed": "IN",
      "availableLanguage": ["en", "Telugu", "Kannada", "Hindi"]
    },
    "sameAs": [
      "https://www.facebook.com/Srivengroups.2024",
      "https://x.com/srivengroups",
      "https://www.instagram.com/srivengroups/?next=%2F&hl=en",
      "https://www.youtube.com/channel/UCSGDeZIcPiM7kICF458aDSQ",
      "https://www.linkedin.com/in/sriven-groups-channelpartners/",
      "https://srivengroups.com/"
    ]
  });
  document.head.appendChild(jsonLDScript2);

  const bootstrapScript = document.createElement("script");
  bootstrapScript.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js";
  bootstrapScript.crossOrigin = "anonymous";
  bootstrapScript.integrity = "sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz";
  document.body.appendChild(bootstrapScript);

  loadScript("https://chatbot.srivengroups.com/chatbot.js", { class: "chatbot-script" });
};
